<template>
  <form action="">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ modalTitle }}</p>
      </header>
      <section class="modal-card-body">
        <b-field label="Car manufacturer *">
          <b-input
            v-model.trim="car.make"
            placeholder="Eg: Volkswagen"
            required
          ></b-input>
        </b-field>
        <b-field label="Car model">
          <b-input
            v-model.trim="car.model"
            placeholder="Eg: Golf"
            required
          ></b-input>
        </b-field>
        <b-field label="License plate number">
          <b-input
            v-model.number="car.licensePlate"
            placeholder="Eg: LJ DE 123"
            required
          ></b-input>
        </b-field>
        <b-field label="Production year">
          <b-input
            v-model.number="car.year"
            placeholder="Eg: 2013"
            type="number"
            min="1970"
            :max="new Date().getFullYear()"
          ></b-input>
        </b-field>
        <b-field label="Mileage">
          <b-input
            v-model.number="car.mileage"
            placeholder="Eg: 130000"
            type="number"
            min="0"
            max="700000"
          ></b-input>
        </b-field>
        <b-field label="Select registration date">
          <b-datepicker
            v-model.trim="car.registrationDate"
            placeholder="Click to select..."
            icon="calendar-today"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button
          @click.prevent="isUpdate ? updateCar() : addNewCar()"
          class="is-primary"
          :loading="isLoading"
          :disabled="!isFormValid"
          >{{ buttonText }}</b-button
        >

        <b-button @click="$parent.close()">Cancel</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
import { httpClient } from '@/api/httpClient.js'

export default {
  data() {
    return {
      isLoading: false,
      car: {
        licensePlate: null,
        make: null,
        model: null,
        year: null,
        mileage: null,
        registrationDate: null
      }
    }
  },
  props: {
    carId: {
      type: String
    }
  },
  computed: {
    isUpdate() {
      return this.carId !== '0'
    },
    isFormValid() {
      return (
        this.car.make &&
        this.car.make !== '' &&
        this.car.model &&
        this.car.model !== '' &&
        this.car.registrationDate &&
        this.car.registrationDate !== '' &&
        this.isYearValid &&
        this.isMilageValid
      )
    },
    isMilageValid() {
      return this.car.mileage > 0 && this.car.mileage <= 700000
    },
    isYearValid() {
      return this.car.year >= 1970 && this.car.year <= new Date().getFullYear()
    },
    buttonText() {
      return this.isUpdate ? 'Update car' : 'Add car'
    },
    modalTitle() {
      return this.isUpdate
        ? 'Update car information'
        : 'Add your car information'
    }
  },
  created() {
    if (this.isUpdate) this.getCarInfo()
  },
  methods: {
    addNewCar() {
      this.isLoading = true

      httpClient
        .post('/cars', {
          ...this.car,
          owner: `/api/users/${this.$store.state.user.userId}`
        })
        .then(() => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Car has been added successfuly.`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'OK'
          })
          this.$emit('success')
          this.$parent.close()
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `${err.message}`,
            type: 'is-danger',
            position: 'is-top',
            actionText: 'Retry'
          })
        })
    },
    updateCar() {
      httpClient
        .put(`cars/${this.carId}`, this.car)
        .then(() => {
          this.$buefy.snackbar.open({
            message: `Car has been added updated.`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'OK'
          })
          this.$emit('success')
          this.$parent.close()
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    },
    getCarInfo() {
      this.isLoading = true
      httpClient
        .get(`cars/${this.carId}`)
        .then(res => {
          this.isLoading = false
          this.car = res.data
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>

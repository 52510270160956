<template>
  <div>
    <Navbar />

    <b-loading :is-full-page="true" :active.sync="isLoading"></b-loading>

    <PageTitle
      :title="'Car detail'"
      :subtitle="'Information about car expeses, fuel consumption'"
    />

    <section class="section">
      <div class="container">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">
              <b-icon icon="car"></b-icon>
              Car #{{ this.car.id }}
            </p>
          </header>
          <div class="card-content">
            <div class="content">
              <label class="label">License plate</label>
              <div class="is-flex">
                <b-icon icon="credit-card"></b-icon>
                <p>{{ this.car.licensePlate }}</p>
              </div>

              <label class="label">Make</label>

              <div class="is-flex">
                <b-icon icon="factory"></b-icon>
                <p>{{ this.car.make }}</p>
              </div>

              <label class="label">Model</label>
              <div class="is-flex">
                <b-icon icon="account"></b-icon>
                <p>{{ this.car.model }}</p>
              </div>

              <label class="label"> Mileage</label>
              <div class="is-flex">
                <b-icon icon="speedometer"></b-icon>
                <p>{{ this.car.mileage }}</p>
              </div>
            </div>
          </div>
          <footer class="card-footer">
            <b-button
              @click="openCarModal"
              class="card-footer-item"
              size="large"
              icon-left="pencil"
              outlined
            >
              Edit
            </b-button>
            <b-button
              @click="confirmCarDelete"
              class="card-footer-item"
              type="is-danger"
              size="large"
              icon-left="delete"
              outlined
            >
              Delete
            </b-button>
          </footer>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <ExpensesByTypeChart :carId="carId" />
      </div>
    </section>

    <section class="section">
      <div class="container">
        <b-button
          class="has-margin-bottom-20"
          type="is-primary"
          @click="goToCarExpenseForm($event, '0')"
          >Add expense</b-button
        >
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">
              <b-icon icon="cash"></b-icon>
              Expenses
            </p>
          </header>
          <div class="card-content">
            <b-table
              :data="expenses"
              :paginated="true"
              per-page="10"
              current-page.sync="1"
              pagination-position="bottom"
              default-sort-direction="asc"
              sort-icon="arrow-up"
              sort-icon-size="is-small"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
              hoverable
              striped
              selectable
              @select="goToExpenseDetail"
            >
              <template slot-scope="props">
                <b-table-column label="Date" sortable>
                  {{ new Date(props.row.createdAt).toLocaleDateString() }}
                </b-table-column>

                <b-table-column field="carMileage" label="Mileage" sortable>
                  {{ props.row.carMileage }}
                </b-table-column>

                <b-table-column field="cost" label="Cost (€)" sortable>
                  {{ props.row.cost }} €
                </b-table-column>

                <b-table-column field="type.name" label="Type" sortable>
                  {{ props.row.type.name }}
                </b-table-column>

                <b-table-column label="Actions">
                  <button
                    class="button is-light"
                    @click="goToCarExpenseForm($event, props.row.id)"
                  >
                    <b-icon icon="pencil"></b-icon>
                  </button>
                  <button
                    class="button is-danger"
                    @click="confirmExpenseDelete($event, props.row.id)"
                  >
                    <b-icon icon="delete"></b-icon>
                  </button>
                </b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                    </p>
                    <p>No expenses for this car yet</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import CarModal from '@/components/CarModal.vue'
import { httpClient } from '@/api/httpClient.js'
import ExpensesByTypeChart from '@/components/ExpensesByTypeChart.vue'
import PageTitle from '@/components/PageTitle.vue'

export default {
  name: 'Car',
  components: {
    Navbar,
    Footer,
    PageTitle,
    ExpensesByTypeChart
  },
  props: {
    carId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      car: {},
      expenses: [],
      yearFilter: 2020
    }
  },
  created() {
    this.getCarInfo()
    this.getCarExpenses()
  },
  computed: {
    lastFiveYears() {
      const currentYear = new Date().getFullYear()
      const arr = []
      for (let i = 0; i < 5; i++) {
        arr.push(currentYear - i)
      }

      return arr
    }
  },
  methods: {
    getCarInfo() {
      this.isLoading = true
      httpClient
        .get(`cars/${this.carId}`)
        .then(res => {
          this.isLoading = false
          this.car = res.data
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    },
    getCarExpenses() {
      this.isLoading = true

      httpClient
        .get(`car-expenses?car=${this.carId}`)
        .then(res => {
          this.isLoading = false
          this.expenses = res.data['hydra:member']
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    },
    confirmExpenseDelete(event, carExpenseId) {
      event.stopPropagation()

      this.$buefy.dialog.confirm({
        title: 'Delete expense',
        message: 'Are you sure you want to this car expense?',
        confirmText: 'Delete expense',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteExpense(carExpenseId)
      })
    },
    deleteExpense(carExpenseId) {
      this.isLoading = true

      httpClient
        .delete(`/car-expenses/${carExpenseId}`)
        .then(() => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Car has been deleted.`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'OK'
          })

          this.getCarExpenses()
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    },
    confirmCarDelete() {
      this.$buefy.dialog.confirm({
        title: 'Delete car',
        message: 'Are you sure you want to delete this car?',
        confirmText: 'Delete car',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteCar(this.carId)
      })
    },
    deleteCar() {
      this.isLoading = true

      httpClient
        .delete(`/cars/${this.carId}`)
        .then(() => {
          this.isLoading = false
          this.$router.replace({ name: 'cars' })

          this.$buefy.snackbar.open({
            message: `Car has been deleted.`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'OK'
          })
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    },
    openCarModal() {
      this.$buefy.modal.open({
        parent: this,
        component: CarModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          carId: this.carId
        },
        events: {
          success: () => {
            this.getCarInfo()
          }
        }
      })
    },
    goToCarExpenseForm(event, carExpenseId) {
      event.stopPropagation()

      this.$router.push({
        name: 'carExpenseForm',
        params: {
          carId: this.car[['@id']],
          carExpenseId: carExpenseId.toString()
        }
      })
    },
    goToExpenseDetail(carExpenseId) {
      this.$router.push({
        name: 'carExpense',
        params: { carExpenseId: carExpenseId.id.toString() }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
